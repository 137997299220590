<template>
  <!-- eslint-disable no-mixed-spaces-and-tabs -->
  <div id="main-wrapper">
    <header class="topbar" data-navbarbg="skin5">
          <Header />
      </header>
      <aside class="left-sidebar" data-sidebarbg="skin5">
          <!-- Sidebar scroll-->
          <div class="scroll-sidebar">
              <!-- Sidebar navigation-->
              <nav class="sidebar-nav">
                <Navbar />
              </nav>
            <!-- End Sidebar navigation -->
          </div>
          <!-- End Sidebar scroll-->
      </aside>
      <div class="page-wrapper">
        <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-12 d-flex no-block align-items-center">
                        <h4 class="page-title">Entry for new Inward Item                           
                        </h4>                        
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Sales Cards  -->
                <!-- ============================================================== -->
                <div class="row mb-3">
                  <div class="col-md-9">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Enter data</h5>
                        <div class="form-group row" data-select2-id="12">
                          <label class="col-md-3 mt-3">Select Item to be stored</label>
                          <div class="col-md-9" data-select2-id="11">
                            <select class="select2 form-select shadow-none select2-hidden-accessible">
                              <option v-for="item in get_items" :value="item.id" :key="item.id">{{item.name}}</option>
                            </select>                      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>                
            </div>
      </div>

      <Footer />
  </div>
  <!-- eslint-disable no-mixed-spaces-and-tabs -->
</template>

<script>
    /* eslint-disable no-mixed-spaces-and-tabs */
      import Header from '@/components/Header';
      import Navbar from '@/components/Nav';
      import Footer from '@/components/Footer';
    export default {
      name: 'add_inward_items',
        components: {
          Header,
          Navbar,
          Footer
        },
        data() {
            return {
                data : [],
                errors: [],
                opened: []
            }
        },
        computed: {
          get_items() {          
            return this.$store.getters.get_items;    
          }
        },
        mounted() {
          this.$store.dispatch("items");
        }
    }
    /* eslint-disable no-mixed-spaces-and-tabs */
</script>